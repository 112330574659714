import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import MenuList from '@mui/material/MenuList';
import ListItemIcon from '@mui/material/ListItemIcon';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import { Colors } from 'constants/colors';
import logo from 'assets/images/logo.svg';
import namedLogo from 'assets/images/logo-with-name.svg';
import {
  CustomMenuIcon,
  Drawer,
  DrawerHeader,
  ExpandArrowContainer,
  Logo,
  LogoContainer,
  MenuItemWrapper,
  NavChildItemText,
  NavItemText,
  StyledLink,
} from './styles';
import { CustomSvgIcon } from '../svg-icon/svg-icon';
import { NavItemProps, navItems } from './constants';
import { getPath } from 'utils/nav/nav-utils';
import { paths } from 'constants/routes';
import { useDispatch } from 'react-redux';
import {
  setApplicableFilters,
  setFilterChips,
  setHighlightColumns,
} from 'store/modules/selected-column/selected-column.reducer';
import Box from '@mui/material/Box';
import { NAVIGATION_DRAWER_PANEL } from 'constants/test-ids';
import { FontSizes } from '../../constants/font-sizes';
import { NavTooltip } from './nav-tooltip';

export const Navigation = (props: any) => {
  const { isDrawerExpanded, connectorsExist, setIsDrawerOpen, isAdmin } = props;
  const { pathname } = useLocation();
  const currentPath = getPath(pathname);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isTooltipOpen, setIsTooltipOpen] = useState<Boolean>(true);

  const [isChildrenOpen, setIsChildrenOpen] = useState<Record<string, boolean>>(
    {
      identities: false,
      assets: false,
      findings: false,
      settings: false,
      resources: false,
      reporting: false,
      recommendations: false,
    },
  );

  const resetFilters = () => {
    dispatch(setApplicableFilters({}));
    dispatch(setFilterChips({}));
    dispatch(setHighlightColumns([]));
    setIsDrawerOpen(false);
    setIsTooltipOpen(false);
    setTimeout(() => {
      setIsTooltipOpen(true);
    }, 500);
  };

  return (
    // <Box sx={{ position: 'relative', zIndex: 1 }}>
    <Drawer
      variant="permanent"
      open={isDrawerExpanded}
      sx={{
        backgroundColor: Colors.blackPearl,
        position: 'absolute',
        zIndex: 1000,
      }}
      data-testid={NAVIGATION_DRAWER_PANEL}
    >
      <DrawerHeader
        sx={{
          height: '58px',
          padding: isDrawerExpanded ? '50px 30px' : '15px 20px',
          background: isDrawerExpanded
            ? Colors.menuGradient
            : Colors.logoContainer,
        }}
      >
        <LogoContainer textOpacity={isDrawerExpanded ? 1 : 0}>
          {isDrawerExpanded ? <Logo src={namedLogo} /> : <Logo src={logo} />}
        </LogoContainer>
      </DrawerHeader>
      <MenuList
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: 'calc(100% - 150px)',
          padding: isDrawerExpanded ? '30px' : '10px',
          paddingTop: '0px',
        }}
      >
        {(isAdmin
          ? navItems
          : [...navItems].filter((item) => !item.isAdmin)
        ).map((item: NavItemProps) => {
          const isActiveItem = item.activePaths
            ? item.activePaths.includes(currentPath)
            : currentPath === paths[item.path];
          return (
            <MenuItemWrapper
              isBottomItem={item.bottomItem}
              key={item.id}
              data-testid={item.dataTestId}
            >
              <NavTooltip
                title={
                  <Box>
                    {' '}
                    <Box
                      sx={{
                        p: '5px',
                        gap: '10px',
                        fontSize: item.children
                          ? FontSizes.fontSize16
                          : FontSizes.fontSize14,
                        lineHeight: item.children ? '24px' : '21px',
                        fontWeight: item.children ? 600 : 500,
                        color: item.children ? Colors.black : Colors.hovered,
                        cursor: item.children ? 'default' : 'pointer',
                      }}
                      onClick={() => {
                        if (!item.children) {
                          resetFilters();
                          navigate(paths[item.path]);
                        }
                      }}
                    >
                      {item.title}
                    </Box>
                    {item.children && (
                      <Box sx={{ mt: '5px' }}>
                        {item.children.map(
                          ({ id, path, title, dataTestId }) => {
                            const isActiveChildItem =
                              currentPath === paths[path];
                            return (
                              <Box
                                sx={{
                                  '&:nth-last-child(1)': {
                                    marginBottom: '0px',
                                  },
                                  minWidth: '135px',
                                  pb: '5px',
                                }}
                                data-testid={dataTestId}
                                onClick={() => {
                                  navigate(paths[path]);
                                  resetFilters();
                                }}
                              >
                                <CustomMenuIcon
                                  isActive={isActiveChildItem}
                                  sx={{
                                    minWidth: '135px',
                                    height: '35px',
                                    borderRadius: '5px',
                                  }}
                                >
                                  <NavChildItemText
                                    connectorsExist={connectorsExist}
                                    noConnectorEnabled={item.noConnectorEnabled}
                                    isActive={isActiveChildItem}
                                  >
                                    {title}
                                  </NavChildItemText>
                                </CustomMenuIcon>
                              </Box>
                            );
                          },
                        )}
                      </Box>
                    )}
                  </Box>
                }
                isTooltipOpen={isTooltipOpen}
                shouldOpen={!isDrawerExpanded}
                placement={'right-start'}
              >
                <Box
                  onClick={() => {
                    if (!item.children) {
                      resetFilters();
                      navigate(paths[item.path]);
                    }
                  }}
                >
                  <CustomMenuIcon
                    onClick={() => {
                      if (isDrawerExpanded) {
                        setIsChildrenOpen((prevState) => {
                          if (Object.keys(isChildrenOpen).includes(item.id)) {
                            return {
                              identities: false,
                              assets: false,
                              settings: false,
                              findings: false,
                              resources: false,
                              reporting: false,
                              recommendations: false,
                              [item.id]: !prevState[item.id],
                            };
                          }
                          return prevState;
                        });
                      }
                    }}
                    isActive={isActiveItem}
                    sx={{
                      height: '41px',
                      backgroundColor: isActiveItem
                        ? Colors.white
                        : 'transparent',
                      borderRadius: isChildrenOpen[item.id]
                        ? '5px 5px 0 0'
                        : '5px',
                    }}
                  >
                    <ListItemIcon>
                      <CustomSvgIcon
                        iconSize={FontSizes.fontSize16}
                        iconColor={
                          isActiveItem
                            ? Colors.hovered
                            : !connectorsExist && !item.noConnectorEnabled
                            ? Colors.menuIcon
                            : Colors.menuIcon
                        }
                        viewBox={'0 0 20 20'}
                        sx={{
                          marginX: 'auto',
                        }}
                      >
                        {item?.icon()}
                      </CustomSvgIcon>
                    </ListItemIcon>
                    <NavItemText
                      isActive={isActiveItem}
                      opacity={isDrawerExpanded ? 1 : 0}
                      connectorsExist={connectorsExist}
                      noConnectorEnabled={item.noConnectorEnabled}
                    >
                      {item.title}
                    </NavItemText>
                    {item.children && isDrawerExpanded && (
                      <ExpandArrowContainer
                        sx={{
                          color: isActiveItem
                            ? Colors.menuText
                            : Colors.menuIcon,
                        }}
                      >
                        {isChildrenOpen[item.id] ? (
                          <ExpandLess />
                        ) : (
                          <ExpandMore />
                        )}
                      </ExpandArrowContainer>
                    )}
                  </CustomMenuIcon>
                </Box>
              </NavTooltip>
              {item.children && (
                <Collapse
                  in={isChildrenOpen[item.id] && isDrawerExpanded}
                  timeout="auto"
                  unmountOnExit
                >
                  <Box
                    className="a"
                    display="grid"
                    sx={{
                      border: isActiveItem
                        ? `1px solid ${Colors.white}`
                        : 'none',
                      borderRadius: '0px 0px 5px 5px',
                      backgroundColor: Colors.white,
                    }}
                  >
                    {item.children.map(({ id, path, title, dataTestId }) => {
                      const isActiveChildItem = currentPath === paths[path];
                      return (
                        <Box
                          sx={{
                            borderWidth: isActiveChildItem ? '1px 0px' : '0px',
                            borderColor: isActiveChildItem
                              ? `${Colors.menuIcon}`
                              : 'none',
                            backgroundColor: isActiveChildItem
                              ? Colors.bodyBackground
                              : Colors.white,
                            paddingLeft: '26px',
                            '&:nth-last-child(1)': {
                              marginBottom: '0px',
                            },
                          }}
                          data-testid={dataTestId}
                        >
                          <StyledLink
                            to={paths[path]}
                            key={id}
                            onClick={resetFilters}
                          >
                            <CustomMenuIcon
                              isActive={false}
                              sx={{
                                padding: '15px',
                                height: '41px',
                                borderRadius: '5px',
                              }}
                            >
                              <NavChildItemText
                                connectorsExist={connectorsExist}
                                noConnectorEnabled={item.noConnectorEnabled}
                                isActive={isActiveChildItem}
                              >
                                {title}
                              </NavChildItemText>
                            </CustomMenuIcon>
                          </StyledLink>
                        </Box>
                      );
                    })}
                  </Box>
                </Collapse>
              )}
            </MenuItemWrapper>
          );
        })}
      </MenuList>
    </Drawer>
    // </Box>
  );
};
